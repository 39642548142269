.block(@className, @rules) {
    @sel: ~".@{className}";

    @{sel} {
        @rules();
    }
}

// .nestedChildBlock() should always be declared inside a .block() mixin
// so that it can access @className from the .block scope
.nestedChildBlock(@childClassName, @rules) {
    // Example:
    // .nestedChildBlock(mceClusterLayout, item, {}) => .mceClusterLayout > .mceClusterLayout-item {}
    @childSel: ~"> .@{className}-@{childClassName}";

    @{childSel} {
        @rules();
    }
}

.resetVar(@prop, @val) {
    @base: ~"--@{className}-@{prop}";

    @{base}: @val;
}

.createGlobalMaps (@globals, @i: 1) when (@i <= length(@globals)) {
    @name: extract(@globals, @i);
    @prop: ~"--@{name}";

    & when (isnumber(@breakpoint)) {
        @{prop}: var(~"--local-@{name}", var(~"--global-@{name}"));
    }

    & when not (isnumber(@breakpoint)) {
        @{prop}: var(~"--local-@{name}@{breakpoint}", var(~"--local-@{name}", var(~"--global-@{name}@{breakpoint}", var(~"--global-@{name}"))));
    }

    .createGlobalMaps(@globals, @i + 1);
}

.createVariableMaps (@vars, @i: 1) when (@i <= length(@vars)) {
    @name: extract(@vars, @i);
    @prop: ~"--@{name}";

    & when (isnumber(@breakpoint)) {
        @{prop}: var(~"--@{className}-@{name}");
    }

    & when not (isnumber(@breakpoint)) {
        @{prop}: var(~"--@{className}-@{name}@{breakpoint}", var(~"--@{className}-@{name}"));
    }

    .createVariableMaps(@vars, @i + 1);
}

.useVariables(@vars) {
    @breakpoint: 0;
    .createVariableMaps(@vars);

    .mobile({
        @breakpoint: -mobile;
        .createVariableMaps(@vars);
    });

    .tablet({
        @breakpoint: -tablet;
        .createVariableMaps(@vars);
    });

    .laptop({
        @breakpoint: -laptop;
        .createVariableMaps(@vars);
    });
}

.useGlobals(@globals) {
    @breakpoint: 0;
    .createGlobalMaps(@globals);

    .mobile({
        @breakpoint: -mobile;
        .createGlobalMaps(@globals);
    });

    .tablet({
        @breakpoint: -tablet;
        .createGlobalMaps(@globals);
    });

    .laptop({
        @breakpoint: -laptop;
        .createGlobalMaps(@globals);
    });
}

.useSpacing() {
    @all: ~"--@{className}-spacing";
    @mobile: ~"@{all}-mobile";
    @tablet: ~"@{all}-tablet";
    @laptop: ~"@{all}-laptop";

    --spacing-factor: var(@all, 1);

    .mobile({
        --spacing-factor: var(@mobile, var(@all, 1));
        /*--spacing-factor: var(--local-spacing-mobile, var(--local-spacing, var(--global-spacing, 1)));*/
    });

    .tablet({
        --spacing-factor: var(@tablet, var(@all, 1));
        /*--spacing-factor: var(--local-spacing-tablet, var(--local-spacing, var(--global-spacing, 1)));*/
    });

    .laptop({
        --spacing-factor: var(@laptop, var(@all, 1));
        /*--spacing-factor: var(--local-spacing-laptop, var(--local-spacing, var(--global-spacing, 1)));*/
    });

    --spacing: calc(var(--global-baseSpacing) ~"*" var(--spacing-factor));
}

// For containers that use proxied backgrounds, this sets up the appropriate
// variables and breakpoints to handle those proxied images correctly.
.useProxyBackgrounds() {
    .resetVar(background, none);
    .resetVar(backgroundSize, none);
    .resetVar(backgroundPosition, none);
    .resetVar(backgroundRepeat, none);
    .resetVar(backgroundImage, none);
    .resetVar(backgroundImage-phone, none);
    .resetVar(backgroundImage-phone-2x, none);
    .resetVar(backgroundImage-tablet, none);
    .resetVar(backgroundImage-tablet-2x, none);
    .resetVar(backgroundImage-laptop, none);
    .resetVar(backgroundImage-laptop-2x, none);
    .resetVar(backgroundImage-desktop, none);
    .resetVar(backgroundImage-desktop-2x, none);

    .useVariables(
        "background"
        "backgroundSize"
        "backgroundPosition"
        "backgroundRepeat"
        "backgroundImage"
        "backgroundImage-phone"
        "backgroundImage-phone-2x"
        "backgroundImage-tablet"
        "backgroundImage-tablet-2x"
        "backgroundImage-laptop"
        "backgroundImage-laptop-2x"
        "backgroundImage-desktop"
        "backgroundImage-desktop-2x"
    );

    background: var(--background, none);
    background-image: var(--backgroundImage, none);
    background-size: var(--backgroundSize);
    background-position: var(--backgroundPosition);
    background-repeat: var(--backgroundRepeat);

    @media screen and (max-width: 480px) {
        background-image: var(--backgroundImage-phone, var(--backgroundImage, none));

        @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: var(--backgroundImage-phone-2x, var(--backgroundImage, none));
        }
    }

    @media screen and (min-width: 481px) and (max-width: 860px) {
        background-image: var(--backgroundImage-tablet, var(--backgroundImage, none));

        @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: var(--backgroundImage-tablet-2x, var(--backgroundImage, none));
        }
    }

    @media screen and (min-width: 861px) and (max-width: 1200px) {
        background-image: var(--backgroundImage-laptop, var(--backgroundImage, none));

        @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: var(--backgroundImage-laptop-2x, var(--backgroundImage, none));
        }
    }

    @media screen and (min-width: 1201px) and (max-width: 1800px) {
        background-image: var(--backgroundImage-desktop, var(--backgroundImage, none));

        @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: var(--backgroundImage-desktop-2x, var(--backgroundImage, none));
        }
    }
}

/**
 * BREAKPOINT HELPERS
 */
.mobile(@rules) {
    @media only screen and (max-width: 480px) {
        @rules();
    }
}

.tablet(@rules) {
    @media only screen and (min-width: 481px) and (max-width: 768px) {
        @rules();
    }
}

.laptop(@rules) {
    @media only screen and (min-width: 769px) and (max-width: 1365px) {
        @rules();
    }
}

/*

*************
* MODIFIERS *
*************

.createModifier() creates a modifier class based on the current context.

In this example, the .button--isFullWidth modifier will be created, along width
breakpoint specific versions like .button--isFullWidth-mobile. Calls to `useVar`
inside the ruleset will be handled intelligently to handle variable breakpoints.

.button {
    .createModifier(isFullWidth, {
        width: 100%;
    })
}

*/

.createModifier(@sel, @rules) {
    @selector: ~"--@{sel}";

    &@{selector} {
        @breakpoint: 0;
        @rules();
    }

    @mobileSelector: ~"--@{sel}-mobile";
    &@{mobileSelector} {
        @breakpoint: mobile;
        .mobile(@rules);
    }

    @tabletSelector: ~"--@{sel}-tablet";

    &@{tabletSelector} {
        @breakpoint: tablet;
        .tablet(@rules);
    }

    @laptopSelector: ~"--@{sel}-laptop";

    &@{laptopSelector} {
        @breakpoint: laptop;
        .laptop(@rules);
    }
}
