/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

p { margin: 0; padding: 0; } /* Remove margin and padding from paragraphs in all browsers */
h1,
h2,
h3,
h4,
h5,
h6 { display: block; margin: 0; padding: 0; }
table { border-collapse: collapse; } /* Prevent double borders */
small { font-size: 80%; } /* Add the correct font size in all browsers */
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sub { bottom: -0.25em; }
sup { top: -0.5em; } /* Prevent \`sub\` and \`sup\` elements from affecting the line height in all browsers */
div,
p,
a { word-break: break-word; } /* Prevent long words from breaking columns */

#root {
    display: flex;
    align-items: center;
    flex-flow: column;

    /* We MUST set an explicit height on the root element for Safari to correctly render the row height. Our explicit height is overridden by the min-height. */
    height: 100px;
    min-height: 100vh;
}

/*////// BROWSER-SPECIFIC STYLES //////*/
img,
a img { border-style: none; height: auto; } /* Remove the border on images inside links in IE 10 */
hr { box-sizing: content-box; height: 0; overflow: visible; } /* Add the correct box sizing in Firefox and show the overflow in Edge and IE */

/* Remove the margin in Firefox and Safari, update font styles in all browsers */
input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
}
legend { padding: 0; }
button,
input { overflow: visible; } /* Show the overflow in Edge */
button,
select { text-transform: none; } /* Remove the inheritance of text transform in Firefox */
a { background-color: transparent; } /* Remove the gray background on active links in IE 10 */
b,
strong { font-weight: bolder; } /* Add the correct font weight in Chrome, Edge, and Safari */

input:focus { border-color: #5d5d5d !important; } /* Active state for input fields in signup form blocks */

/* Prosemirror nests paragraphs inside list items */
li p { margin: 0 !important; }

/*////// MOBILE STYLES //////*/
@media only screen and (max-width: 480px) {
    /*////// STRUCTURAL STYLES //////*/
    .mceColumn { grid-column-start: 1; grid-column-end: 13; }
    .mceRow { grid-template-columns: 100%; gap: 18px 0; }

    img { height: auto !important; }
}

/*////// EDITING STYLES //////*/
.useGlobals(
    "paragraphTextColor"
);

div[contenteditable="true"] { outline: 0; }
.ProseMirror .empty-node,
.ProseMirror:empty { position: relative; }
.ProseMirror .empty-node::before,
.ProseMirror:empty::before {
    position: absolute;
    left: 0;
    right: 0;
    color: var(--paragraphTextColor);
    opacity: 0.6;
    cursor: text;
}

.ProseMirror .empty-node:hover::before,
.ProseMirror:empty:hover::before {
    color: var(--paragraphTextColor);
    opacity: 0.8;
}

.ProseMirror h1.empty-node:only-child::before,
.ProseMirror h2.empty-node:only-child::before,
.ProseMirror h3.empty-node:only-child::before,
.ProseMirror h4.empty-node:only-child::before {
    content: 'Heading';
}

.ProseMirror p.empty-node::before,
.ProseMirror:empty::before {
    content: 'Paragraph';
}

button .ProseMirror p.empty-node::before,
button .ProseMirror:empty::before,
a .ProseMirror p.empty-node::before,
a .ProseMirror:empty::before {
    content: '';
}

.ProseMirror {
    white-space: pre-wrap;
}

.mceEmbeddedMedia {
    width: 100%;
    overflow: hidden;

    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;
    iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.mceEmbeddedMediaDefault {
    width: 100%;
}
