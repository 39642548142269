@import (reference) "./mixins";

.mceButton {
    // List globals this block uses
    @globals:
        "buttonFontFamily"
        "paragraphFontFamily"
        "buttonBackgroundColor"
        "buttonHoveredBackgroundColor"
        "buttonFontSize"
        "buttonVerticalPadding"
        "buttonHorizontalPadding"
        "buttonWidthPercentage"
        "buttonColor"
        "buttonBorderSize"
        "buttonBorderStyle"
        "buttonBorderColor"
        "buttonBorderRadius"
        "buttonBoxShadowHOffset"
        "buttonBoxShadowVOffset"
        "buttonBoxShadowBlur"
        "buttonBoxShadowSpread"
        "buttonBoxShadowColor"
        "buttonFontWeight"
        "buttonTransitionProperty"
        "secondaryButtonBackgroundColor"
        "secondaryButtonBorderColor"
        "secondaryButtonColor"
        "secondaryButtonTextDecoration";

    .useGlobals(@globals);

    width: auto;

    button,
    a {
        display: inline-block;
        text-align: center;
        min-width: 50px;
        text-decoration: none;
        cursor: pointer;

        font-family: var(--buttonFontFamily, var(--paragraphFontFamily));
        font-size: var(--buttonFontSize);
        font-weight: var(--buttonFontWeight);
        color: var(--buttonColor);
        background-color: var(--buttonBackgroundColor);
        padding: var(--buttonVerticalPadding) var(--buttonHorizontalPadding);
        border-width: var(--buttonBorderSize);
        border-style: var(--buttonBorderStyle);
        border-color: var(--buttonBorderColor);
        border-radius: var(--buttonBorderRadius);
        transition: var(--buttonTransitionProperty) 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);

        box-shadow:
            var(--buttonBoxShadowHOffset)
            var(--buttonBoxShadowVOffset)
            var(--buttonBoxShadowBlur)
            var(--buttonBoxShadowSpread)
            var(--buttonBoxShadowColor);

        &:hover {
            background-color: var(--buttonHoveredBackgroundColor, var(--buttonBackgroundColor));
            box-shadow:
                6px
                6px
                var(--buttonBoxShadowBlur)
                var(--buttonBoxShadowSpread)
                var(--buttonBoxShadowColor);
        }
    }

    .createModifier(isFullWidth, {
        width: 100%;

        button,
        a {
            width: 100%;
            display: block;
            padding: var(--buttonVerticalPadding);
        }
    });

    .createModifier(secondary, {
        button, a {
            background-color: var(--secondaryButtonBackgroundColor);
            border-color: var(--secondaryButtonBorderColor, transparent);
            color: var(--secondaryButtonColor);
            text-decoration: var(--secondaryButtonTextDecoration, none) underline;
        }
    });
}
