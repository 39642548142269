@import (reference) "./mixins";

.block(mceRow, {
    .resetVar(spacing, 1);
    .resetVar(paddingLeft, 1);
    .resetVar(paddingRight, 1);
    .resetVar(paddingBottom, 1);
    .resetVar(paddingTop, 1);
    .resetVar(width, 100%);
    .resetVar(gridTemplateColumns, repeat(12, 1fr));
    .resetVar(rowContentAlignment, initial);

    .useVariables(
        "paddingTop"
        "paddingLeft"
        "paddingRight"
        "paddingBottom"
        "width"
        "gridTemplateColumns"
        "rowContentAlignment"
        "zIndex"
    );
    .useSpacing();
    .useProxyBackgrounds();

    display: grid;
    grid-template-columns: var(--gridTemplateColumns);
    gap: var(--spacing);
    width: var(--width, 100%);
    z-index: var(--zIndex);

    place-content: var(--rowContentAlignment);

    padding-top: calc(var(--paddingTop, 1) ~"*" var(--global-baseSpacing));
    padding-bottom: calc(var(--paddingBottom, 1) ~"*" var(--global-baseSpacing));
    padding-left: calc(var(--paddingLeft, 1) ~"*" var(--global-baseSpacing));
    padding-right: calc(var(--paddingRight, 1) ~"*" var(--global-baseSpacing));

    &:last-of-type {
        flex-grow: 2;
    }

    .createModifier(fixedBottom, {
        position: fixed;
        bottom: 0;
    });

    .createModifier(fixedTop, {
        position: fixed;
        top: 0;
    });

    .createModifier(relative, {
        position: relative;
    });

    .createModifier(maxWidth, {
        max-width: var(--global-maxWidth);
    });

    .createModifier(noSpacing, {
        gap: 0;
        padding: 0;
    });

    .createModifier(isFullBleed, {
        padding: 0;

        .createModifier(noSpacing, {
            gap: 0px;
        });
    });

    .createModifier(singleColumn, {
        flex-direction: column;
        display: flex;
        flex-shrink: 0;

        .createModifier(stretchHeight, {
            flex-basis: auto;
        });
    });

    .createModifier(stretchHeight, {
        flex-grow: 1;
        flex-basis: 0;
    });

    .createModifier(hidden, {
        display: none;
    });
});
