@import (reference) "./mixins.less";

.block(mceForm, {
    .useGlobals(
        "paragraphTextColor"
        "linkTextColor"
        "inputWidth"
    );

    .useVariables(
        "color"
        "linkColor"
    );

    .useSpacing();
    width: 100%;

    .mceForm-element {
        width: var(--inputWidth);
        margin: 0 auto;
        > * {
            margin-bottom: var(--spacing);

            &:last-child {
                margin-bottom: 0;
            }
        }
        .tablet({
            width: 100%;
        });
        .mobile({
            width: 100%;
        });
    }

    color: var(--local-textColor, var(--paragraphTextColor));

    .mceFormFeedback {
        padding: var(--spacing);
        margin-bottom: var(--spacing);

        &--error {
            background-color: #fbcfbd;
            color: #241c15;
        }
        &--success {
            background-color: #d8eacc;
            color: #241c15;
        };
        a {
            color: #241c15;
        }
    }

    .mceErrorMessage {
        color: #a73205;
        text-align: left;
        margin-top: calc(var(--spacing) ~"/" 2);

        &--centered {
            text-align: center;
        }
    }

    .mceInput--error {
        // Use !important to override the inline style
        border-color: #a73205 !important;

        &[type="radio"],
        &[type="checkbox"] {
            outline: 1px solid #a73205;
        }
    }

    .createModifier(darkBackground, {
        .mceErrorMessage {
            color: #fbcfbd;
        }
        .mceInput {
            &--error {
                // Use !important to override the inline style
                border-color: #fbcfbd !important;

                &[type="radio"],
                &[type="checkbox"] {
                    outline: 1px solid #fbcfbd;
                }
            }
            &::placeholder {
                color: #ddd;
            }

        }
        .mceInput[type="date"] {
            &::-webkit-calendar-picker-indicator {
                /*
                  Chrome doesn't support setting the color of the date picker indicator yet
                  but we can use this filter in the meantime to ensure it's always visible
                  against the form background.
                */
                filter: invert(1);
            }
        }
    });
});

.block(mceGdprForm, {
    .useGlobals("baseFontSize");
    .useSpacing();

    clear: both;
    margin-bottom: var(--spacing);

    > * + * {
        margin-top: var(--spacing);
    }

    .mceFieldset {
        margin-top: var(--spacing);
    }

    .mceErrorMessage {
        margin-bottom: calc(var(--spacing) ~"/" 2);
    }

    .mceLegalContainer {
        display: flex;
        align-items: center;
        font-size: calc(var(--baseFontSize) ~"*" 0.8);

        img {
            max-width: 64px;
            margin-right: var(--spacing);
        }

        a {
            color: var(--local-linkColor, var(--linkTextColor));
        }
    }
});
