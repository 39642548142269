//apply CSS variables to header
#root {
    [data-js-target='headerWithNav'] {
        position: var(--headingAdjustPosition);
        top: var(--headingAdjustTop);
    }
}

//apply CSS padding variable to first element
#root {
    [data-js-target='headerWithNav'] {
        & + .mceRow > div:first-child {
            padding-top: var(--paddingAdjustFirstSection);
        }
    }
}
