/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import (reference) "./mixins";

@mobile-width: 480px;
@tablet-width: 768px;
@laptop-width: 1365px;

[data-js-target='headerWithNav'] {
    .useGlobals(
        "linkTextColor"
    );

    nav {
        @media screen and (max-width: @tablet-width) {
            display: none;
            position: absolute;
        }
    }

    &.runtimeTransparentBackground {
        background-color: transparent;
    }

    [data-ref='hamburgerIconRef'] {
        height: 36px;
        width: 36px;
        display: none;
        fill: var(--linkTextColor);

        @media screen and (max-width: @tablet-width) {
            height: 24px;
            width: 24px;
            display: grid;
        }
    }

    &[class*="inViewport"] {
        [data-ref='hamburgerIconRef']:not(:empty) {
            cursor: pointer;
        }
    }

    :not(#cartDropdown) div[class='.mceButton'] {
        @media screen and (max-width: @tablet-width) {
            display: none;
        }
    }

    z-index: 1;
}

.responsiveNavigationDrawerContainer {
    .useGlobals(
        "backgroundColor"
        "linkTextColor"
        "paragraphFontFamily"
        "baseFontSize"
        "baseSpacing"
    );

    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    overflow-y: scroll;
    background-color: transparent;
    transition: background-color 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    visibility: hidden;

    > div {
        display: flex;
        height: 100%;
        width: 35rem;
        padding: 0 calc(4 * var(--baseSpacing));
        max-width: 100vw;
        background-color: var(--backgroundColor);
        font-family: var(--paragraphFontFamily);
        flex-direction: column;
        transition: transform 0.5s cubic-bezier(0.51, 0.29, 0.1, 1.13) 0s;
        z-index: 100;
        transform: translate(-100%, 0);

        @media screen and (max-width: @tablet-width) {
            padding: 0 calc(3 * var(--baseSpacing));
            width: 85%;
            transition-duration: 0.4s;
        }

        @media screen and (max-width: @mobile-width) {
            padding: 0 calc(1 * var(--baseSpacing, 24px));
        }

        &::before {
            content: " ";
            display: block;
            background-color: var(--backgroundColor);
            height: 100%;
            width: 30px;
            position: absolute;
            left: -30px;
            top: 0;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: var(--baseFontSize);
        }
        ul li {
            margin-bottom: 16px;
        }
        ul li a {
            text-decoration: none;
            color: var(--linkTextColor);

            &:hover {
                text-decoration: underline;
            }
        }

        .closeButton {
            color: var(--linkTextColor);
            fill: var(--linkTextColor);
            height: 36px;
            width: 36px;
            margin-top: -18px;
            margin-bottom: 36px;
            cursor: pointer;

            @media screen and (max-width: @tablet-width) {
                height: 24px;
                width: 24px;
                margin-top: -12px;
                margin-bottom: 24px;
            }
        }

        .mceButton {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
        }
    }

    &.visible {
        background-color: rgba(36, 28, 21, 0.3);

        @media screen and (max-width: @tablet-width) {
            visibility: visible;
        }

        > div {
            transform: translate(0, 0);
        }
    }

    &.hiding {
        visibility: visible;
    }

    @media (prefers-reduced-motion) {
        & {
            transition-duration: 0s;
        }

        > div {
            transition-duration: 1ms; // can't be zero because responsiveNav.js listens for transitionend event
        }
    }
}

[data-js-target='headerWithNav'][data-layout='leftDrawer'] {
    [data-ref='hamburgerIconRef'] {
        display: grid;
    }

    .responsiveNavigationDrawerContainer {
        &.visible {
            visibility: visible;
        }
    }
}
