@import (reference) "./mixins.less";

@helpertext-color: #4d4d4d;

.block(mceInput, {
    .useGlobals(
        "baseFontSize"
        "paragraphFontFamily"
        "inputBackgroundColor"
        "inputBorderRadius"
        "inputBorderSize"
        "inputBorderStyle"
        "inputBorderColor"
        "inputColor"
    );

    .useSpacing();

    background-color: var(--inputBackgroundColor);
    border-radius: var(--inputBorderRadius);
    border: var(--inputBorderSize) var(--inputBorderStyle) var(--inputBorderColor);
    width: 100%;
    display: inline-block;
    color: var(--inputColor);
    padding: 0 var(--spacing);
    font-family: var(--paragraphFontFamily);
    font-size: calc(var(--baseFontSize) ~"*" 0.8);
    margin-bottom: var(--spacing);
    height: calc(var(--baseFontSize) ~"*" 2.5);

    &[type="radio"],
    &[type="checkbox"] {
        margin-right: var(--spacing);
        display: inline-block;
        width: auto;
        height: auto;
    }

    .mobile({
        width: 100%;
    });
});

.block(mceSelect, {
    .useGlobals(
        "baseFontSize"
        "paragraphFontFamily"
        "inputBackgroundColor"
        "inputBorderRadius"
        "inputBorderSize"
        "inputBorderStyle"
        "inputBorderColor"
        "inputColor"
        "inputWidth"
    );

    .useSpacing();

    cursor: pointer;
    background-color: var(--inputBackgroundColor);
    border-radius: var(--inputBorderRadius);
    border: var(--inputBorderSize) var(--inputBorderStyle) var(--inputBorderColor);
    width: var(--inputWidth, 100%);
    display: inline-block;
    color: var(--inputColor);
    padding: 0 var(--spacing);
    font-family: var(--paragraphFontFamily);
    font-size: calc(var(--baseFontSize) ~"*" 0.8);
    margin-bottom: var(--spacing);
    height: calc(var(--baseFontSize) ~"*" 2.5);
});

.block(mceLabel, {
    .useGlobals(
        "baseFontSize"
        "paragraphFontFamily"
        "paragraphTextColor"
    );

    .useVariables(
        "fontWeight"
        "color"
    );

    .useSpacing();

    color: var(--color, var(--paragraphTextColor));
    font-weight: var(--fontWeight, 'bold');
    font-size: calc(var(--baseFontSize) ~"*" 0.9);
    font-family: var(--paragraphFontFamily);
    display: block;
    margin-bottom: calc(var(--spacing) ~"*" 1.5);
    text-align: left;

    > .mceLabel--requiredIndicator {
        padding-left: 1em;
    }

    > .iti {
        width: 100%;
    }

    > .iti > .iti__flag-container {
        height: calc(var(--baseFontSize) ~"*" 2.5);
    }
});

.block(mceHelperText, {
    .useGlobals(
        "baseFontSize"
        "paragraphFontFamily"
        "paragraphTextColor"
    );

    .useVariables(
        "fontWeight"
        "color"
    );

    .useSpacing();
    color: lighten(@helpertext-color, 45%);
    font-weight: var(--fontWeight, 'bold');
    font-size: calc(var(--baseFontSize) ~"*" 0.9);
    font-family: var(--paragraphFontFamily);
    display: block;
    margin-bottom: calc(var(--spacing) ~"*" 1.5);
    text-align: left;
});

.block(mceFieldset, {
    .useSpacing();
    margin: 0 0 var(--spacing);
    padding: 0;
    border: 0;
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: calc(var(--baseFontSize) ~"*" 0.9);

    legend {
        margin-bottom: var(--spacing);
    }

    > .mceLabel {
        margin-top: calc(var(--spacing) ~"/" 2);
        margin-bottom: 0;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .mceInput {
        margin-bottom: 0;
    }
});


.block(mceInputGroup, {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: calc(var(--spacing) ~"*" 1.5);

    > .mceLabel  {
        margin-bottom: 0;
    }

    > .mceLabel > .iti,
    > .mceLabel > .mceInput,
    > .mceSelect {
        margin-top: calc(var(--spacing) ~"/" 2);
        margin-bottom: 0;
    }
});
