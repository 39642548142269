/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import (reference) "./mixins";
@import (reference) "@mc/wink/styles/tokens.less";

[data-js-target="carousel"] {
    @globals:
        "headingTextColor";

    .useGlobals(@globals);

    .uiControl() {
        background-color: transparent;
        border: none;

        svg {
            stroke: var(--headingTextColor, @brand-peppercorn);
        }

        &:hover {
            cursor: pointer;
        }
    }

    [data-ref^="carousel-slide-control-"] {
        display: flex;

        > [data-ui-control] {
            .uiControl();

            padding: 24px 12px;

            &[value="previous"] {
                margin-left: -12px;
            }

            &[value="next"] {
                margin-right: -12px;
            }
        }
    }

    [data-ref="carousel-slide-picker"] {
        white-space: nowrap;

        > [data-ui-control] {
            .uiControl();

            padding: 0;
            margin: 0 4px;

            &:hover {
                svg {
                    fill: var(--headingTextColor, @brand-peppercorn);
                }
            }

            &[aria-disabled="true"] {
                svg {
                    fill: var(--headingTextColor, @brand-peppercorn) !important;
                    stroke: var(--headingTextColor, @brand-peppercorn) !important;
                }
            }
        }
    }

    [data-js-target="carousel-slides"] {
        ~ .mceColumn {
            padding: 0;
        }

        > * {
            align-self: center;
            grid-row: 1;
            grid-column: 1;
            opacity: 0;
            visibility: hidden;

            &.mceCarousel-slide-previous {
                transform: translateX(-100%);
            }

            &.mceCarousel-slide-next {
                transform: translateX(100%);
            }

            &.mceCarousel-slide-rotating {
                visibility: visible;
                transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.3s cubic-bezier(1, 0, 0, 1);

                @media (prefers-reduced-motion) {
                    & {
                        transition-duration: 1ms; // can't be zero because carouseBlock.js listens for transition events
                    }
                }
            }

            &.mceCarousel-slide-current {
                visibility: visible;
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}
