@import (reference) "./mixins.less";
@import (reference) "css/less/global-variables/all.less";

.block(mceImageGallery, {
    .useGlobals("buttonBackgroundColor");

    .mceImageGallery-main--hide {
        display: none;
    }

    .mobile({
        .mceImageGallery-main-wrapper {
            display: block;
            margin: 0 calc(~"50% - 50vw");
        }

        .mceImageGallery-main-strip {
            display: flex;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            // --containerSize is set in the imageGallery runtime JS
            height: var(--containerSize, 100%);

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .mceImageGallery-main-image-wrapper {
            flex: 0 0 var(--containerSize, 100%);
            scroll-snap-stop: always;
            scroll-snap-align: center;
            margin-left: 12px;

            &:first-child {
                margin-left: calc(~"100% - (var(--containerSize) / 2)");
            }

            &:last-child {
                margin-right: calc(~"100% - (var(--containerSize) / 2)");
            }
        }

        .mceImageGallery-main--hide {
            display: block;
        }
    });

    button {
        // Styles specifically for the buttons that
        // wrap the thumbnails.
        display: block;
        cursor: pointer;
        border: 0;
    }

    .mceImageGallery-thumb--selected {
        box-shadow: inset 0 -5px 0 0 var(--buttonBackgroundColor);

        button.mceBox {
            z-index: @z1 - 3;
        }
    }

    @media screen and (max-width: 480px) {
        .mceImageGallery-thumb {
            picture {
                display: none;
            }
        }

        .mceImageGallery-thumb {
            height: 4px;

            button {
                background-color: @peppercorn-fade--30;
            }
        }

        .mceImageGallery-thumb--selected {
            button {
                background-color: var(--buttonBackgroundColor);
            }
        }
    }
});
