/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import (reference) "./mixins";

@globals:
    "animationDuration"
    "animationType"
    "animationEasing";

.useGlobals(@globals);

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideUp {
    0% {
        transform: translate3d(0,20px,0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}

@keyframes grow {
    0% {
        transform: scale(0.85);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

[data-animation-status="not-started"],
[data-animation-status="entering"] { opacity: 0; }

.inViewport [data-animation-status] {
    transform: translate3d(0, 0, 0) scale(1);
    animation: var(--animationType) var(--animationDuration) var(--animationEasing);
}

.inViewport [data-animation-status="complete"] {
    transform: none;
    animation: none;
    opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
    .inViewport [data-animation-status] {
        transform: none;
        animation: none;
        opacity: 1;
    }
}
