@import (reference) "./mixins";

.commerceErrorWrap {
    .useGlobals(
        "paragraphFontFamily"
        "paragraphTextColor"
    );

    padding-left: calc(var(--paddingLeft, 3) ~"*" var(--global-baseSpacing));
    padding-right: calc(var(--paddingRight, 3) ~"*" var(--global-baseSpacing));
    width: 100%;
    max-width: var(--global-maxWidth);
    box-sizing: border-box;

    .commerceError {
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid var(--paragraphTextColor);
        color: var(--paragraphTextColor);
        font-family: var(--paragraphFontFamily);
        max-width: --global-maxWdith;
        width: 100%;
        padding: 18px 40px 18px 18px;

        .wink-icon {
            width: 24px;
            flex-shrink: 0;
            height: auto;
            margin-right: 20px;
            fill: var(--paragraphTextColor);
        }

        .closeButton {
            position: absolute;
            right: 12px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 18px;
                height: auto;
                fill: var(--paragraphTextColor);
            }

            @media screen and (max-width: 480px) {
                top: 12px;
            }
        }
    }
}
