/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import (reference) "./mixins";

// the .mceRuntime class is applied to the body element, so these
// resets need to happen outside of the .mceRuntime scope below.
// All other resets should go in resets.less.
html { line-height: 1.15; -webkit-text-size-adjust: 100%; }

body.mceRuntime {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;

    * {
        box-sizing: border-box;
    }

    /* Reset for the nav elements to prevent word break on large logos */
    p,
    a {
        word-break: initial;
    }
}

// Weary devs looking to place styles in the editor ONLY, rest here. You found it.
// isViewer is only appended in the editing context :)
.isViewer {
    .mceEmbeddedMedia > iframe {
        pointer-events: none;
    }
}

.mceRuntime {
    // adjust the global font sizes down a bit for tablet and mobile
    @laptopTextSizeFactor: 0.98;
    @tabletTextSizeFactor: 0.94;
    @mobileTextSizeFactor: 0.9;

    --global-baseFontSize-mobile: calc(var(--global-baseFontSize) ~"*" @mobileTextSizeFactor);
    --global-baseFontSize-tablet: calc(var(--global-baseFontSize) ~"*" @tabletTextSizeFactor);
    --global-baseFontSize-laptop: calc(var(--global-baseFontSize) ~"*" @laptopTextSizeFactor);

    --global-buttonFontSize-mobile: calc(var(--global-buttonFontSize) ~"*" @mobileTextSizeFactor);
    --global-buttonFontSize-tablet: calc(var(--global-buttonFontSize) ~"*" @tabletTextSizeFactor);
    --global-buttonFontSize-laptop: calc(var(--global-buttonFontSize) ~"*" @laptopTextSizeFactor);

    // Default to 1.25 for historical reasons, it was the original type scale for sites. For newer
    // sites or sites that support SSR, we set this to 1.333 in document/transforms
    --type-scale: var(--global-typeScale, 1.25);

    // By default, use the type scale directly. This covers everything larger than the
    // laptop breakpoint.
    --final-type-scale: var(--type-scale);

    // For other breakpoints, ratio the type scale down based on the breakpoint's factor.
    .mobile({
        --final-type-scale: calc(var(--type-scale) ~"*" @mobileTextSizeFactor);
    });

    .tablet({
        --final-type-scale: calc(var(--type-scale) ~"*" @tabletTextSizeFactor);
    });

    .laptop({
        --final-type-scale: calc(var(--type-scale) ~"*" @laptopTextSizeFactor);
    });

    // Set the h1-h4 type scale based on the final type-scale
    // see: https://type-scale.com/
    --global-h1FontScale: calc(var(--final-type-scale) ~"*" var(--final-type-scale) ~"*" var(--final-type-scale) ~"*" var(--final-type-scale));
    --global-h2FontScale: calc(var(--final-type-scale) ~"*" var(--final-type-scale) ~"*" var(--final-type-scale));
    --global-h3FontScale: calc(var(--final-type-scale) ~"*" var(--final-type-scale));
    --global-h4FontScale: var(--final-type-scale);

    // Common utilities used across all blocks
    @import './resets.less';
    @import './utilities.less';
    @import './animations.less';

    // Blocks
    @import './cartIcon.less';
    @import './cluster-layout.less';
    @import './code.less';
    @import './column.less';
    @import './commerceError.less';
    @import './divider.less';
    @import './form.less';
    @import './image.less';
    @import './box.less';
    @import './input.less';
    @import './row.less';
    @import './stack-layout.less';
    @import './grid-layout.less';
    @import './text.less';
    @import './services.less';
    @import './freeShippingNotification.less';
    @import './spacer.less';
    @import './section.less';
    @import './wrapper.less';
    // Button needs to come after text.less for specificity tie-breakers
    // around link color.
    @import './button.less';
    @import './imageGallery.less';
    @import './headerWithNav.less';
    @import './commerceProductListing.less';
    @import './carousel.less';
}
