@import (reference) "./mixins";

.mceCartIcon {
    .useGlobals(
        "backgroundColor"
        "linkTextColor"
        "paragraphFontFamily"
        "headingFontWeight"
        "paragraphTextColor"
        "buttonBackgroundColor"
    );

    position: relative;
    margin-left: 24px;

    svg {
        fill: var(--linkTextColor);
    }

    #cartLink {
        display: block;
        padding: 5px; //add a little padding to make it easier to hover/click on
    }

    .mceCartCount {
        background: var(--linkTextColor);
        width: 14px;
        height: 14px;
        border-radius: 50%;
        font-size: 8px;
        color: var(--backgroundColor);
        position: absolute;
        top: 11px;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: var(--headingFontWeight);
        font-family: var(--paragraphFontFamily);
    }

    .mceCartDropdown {
        color: var(--paragraphTextColor);
        background: var(--backgroundColor);
        border: 1px solid var(--linkTextColor);
        width: 400px;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 10;
        font-family: var(--paragraphFontFamily);
        box-shadow: 0 12px 24px rgba(36, 28, 21, 0.16);
        overflow-y: auto;
        overflow-x: hidden;
        display: none;

        .cartMessage {
            padding: 20px 20px 0;
            font-size: 20px;
        }

        .closeWrap {
            position: sticky;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            background: var(--global-backgroundColor);
            height: 30px;
            z-index: 10;
        }

        .close {
            position: absolute;
            cursor: pointer;
            top: 5px;
            right: 5px;
            width: 30px;
            height: 30px;
            border: 0;
            background: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media screen and (max-width: 480px) {
            position: fixed;
            top: 20px;
            left: 20px;
            right: 20px;
            width: auto;
            max-height: 95vh;
        }
    }

    .subtotalWrap {
        position: sticky;
        padding: 20px;
        bottom: 0;
        background-color: var(--global-backgroundColor);

        .subtotal {
            display: flex;
            justify-content: space-between;
            font-weight: 700;
            margin: 20px 0;
        }
    }

    .mceButton {
        a {
            width: 100%;
        }
        margin: 0 20px;
    }

    .lineItem {
        padding: 20px;
        display: grid;
        flex-direction: row;
        grid-template-columns: 1fr 2fr;
        margin-bottom: 16px;
        grid-gap: 16px;
        border-bottom: 1px solid var(--paragraphTextColor);

        .productTitle {
            font-weight: 700;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 4px;

            .itemPrice {
                word-break: normal;
            }
        }

        .productDetails {
            display: grid;
            grid-gap: 16px;
            grid-template-columns: auto 1fr;
        }

        .price {
            white-space: nowrap;
            padding-left: 8px;
        }

        .imageWrap {
            width: 100%;
            padding-top: 100%;
            position: relative;

            .inner {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;
            }

            img {
                max-width: 100%;
                min-height: 100%;
                object-fit: cover;
            }
        }
    }
}
