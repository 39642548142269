@import (reference) "./mixins.less";

.block(mceServices, {
    .iti__flag {background-image: url("https://eep.io/mc-cdn-images/intl-tel-input/flags.png");}

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .iti__flag {background-image: url("https://eep.io/mc-cdn-images/intl-tel-input/flags@2x.png");}
    }

    .useGlobals(
        "baseFontSize"
        "paragraphFontFamily"
        "buttonBackgroundColor"
        "buttonBorderColor"
        "buttonColor"
        "secondaryButtonBackgroundColor"
        "secondaryButtonBorderColor"
        "secondaryButtonColor"
        "secondaryButtonTextDecoration"
    );

    .useVariables(
        "color"
        "linkColor"
        "fontSize"
    );

    .useSpacing();
    width: 100%;

    height: auto;
    overflow: hidden;

    .mobile ({
        padding-right: 1px;
    });

    .mceServices-header {
        display: flex;
        align-items: center;
        padding: var(--spacing);
    }

    .mceServices-empty {
        padding: var(--spacing) 0;
    }

    .mceServices-headerBullet {
        border-radius: 50%;
        display: flex;
        align-content: center;
        justify-content: center;

        width: calc(2 ~"*" var(--baseFontSize));
        height: calc(2 ~"*" var(--baseFontSize));
        line-height: calc(2 ~"*" var(--baseFontSize));
        background-color: var(--buttonBackgroundColor);
        color: var(--buttonColor);
        margin-right: var(--spacing);

        .mobile({
            display: none;
        });
    }

    .mceServices-collapsed {
        padding: var(--spacing);
        padding-left: calc(2 ~"*" var(--baseFontSize) ~"+" var(--spacing));
        display: flex;
        justify-content: space-between;
        .mobile({
            padding-left: 0;
        });
    }
    .mceServices-services {
        padding-left: calc(2 ~"*" var(--baseFontSize) ~"+" var(--spacing));
        .mobile({
            padding-left: 0;
            padding-right: 0;
        });
        .mceDivider {
            margin: 0;
        }
        .mceDivider:last-child { display: none;}
    }
    .mceServices-service {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: calc(0.9 ~"*" var(--baseFontSize));
        padding: var(--spacing);
        .mobile({
            flex-wrap: wrap;
            align-items: flex-start;
            padding-left: 0;
            padding-right: 0;
        });
        &-information {
            width: 100%;
            &-sessionType {
                font-style: italic;
                padding-top: calc(var(--spacing) ~"*" 0.5);
            }
            h4 {
                font-family: var(--paragraphFontFamily);
            }
            p {
                padding-bottom: calc(var(--spacing) ~"*" 0.5);
            }
            .mobile({
                width: 70%;
                padding-bottom: var(--spacing);
            });
        }
        &-priceHelperText {
            display: block;
            margin-top: calc(var(--spacing) ~"*" 0.5);
            font-size: calc(0.9 ~"*" var(--baseFontSize));
        }
        &-price {
            justify-self: flex-end;
            font-size: calc(var(--fontSize, 0.8) ~"*" var(--baseFontSize));
            margin-right: calc(2 ~"*" var(--spacing));
            white-space: nowrap;
            text-align: right;
            .mobile({
                width: 30%;
                margin-right: 0;
            });
            &-disclaimer {
                white-space: normal;
                display: block;
                font-style: italic;
                .mobile({
                    font-size: calc(var(--fontSize, 0.7) ~"*" var(--baseFontSize));
                });
            }
        }
        .mceButton {
            justify-self: flex-end;
            white-space: nowrap;
            .mobile({
                width: 100%;
                button {
                    white-space: normal;
                    width: 100%;
                }
            });
        }
    }

    .editButton {
        background-color: transparent;
        border: none;
    }

    #detailsCharacterCounter {
        display: flex;
        justify-content: flex-end;
        font-size: calc(var(--baseFontSize) ~"*" 0.9);
        padding-top: calc(var(--spacing) ~"/" 2);
    }
    .mceServices-checkbox-container {
        width: 100% !important;
    }
    .mceServices-invalidCharacterLength {
        color: #a73205;
    }

    .mceServices-contactDetails {
        padding-left: calc(2 ~"*" var(--baseFontSize) ~"+" var(--spacing));
        .mobile({
            padding-left: 0;
        });
        &--fields {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .mceInputGroup {
                width: 48%;
                margin-right: 2%;
                &:nth-child(5) {
                    width: 100%;
                }
            }
            .mobile({
                .mceInputGroup {
                    width: 100%;
                    margin-right: 0;
                }
            });
        }
        .mceButton {
            justify-self: flex-end;
            white-space: nowrap;
            .mobile({
                width: 100%;
                button {
                    white-space: normal;
                    width: 100%;
                }
            });
        }
    }

    .mceServices-date-and-time-wrapper {
        margin-top: var(--spacing);
        padding-left: calc(2 ~"*" var(--baseFontSize) ~"+" var(--spacing));

        .mobile({
            padding-left: 0;
        });
        .mceButton {
            justify-self: flex-end;
            white-space: nowrap;
            .mobile({
                width: 100%;
                white-space: normal;
                button {
                    width: 100%;
                }
            });
        }
    }

    .mceServices-date-and-time {
        display: flex;
        flex-direction: row;

        .tablet({
            flex-direction: column;
        });

        .mobile({
            flex-direction: column;
        });
    }

    .mceServices-date-and-time-button-container {
        display: flex;
        justify-content: center;
        margin-top: @base-unit * 6;
        padding-right: @base-unit * 32;
        .tablet({
            padding-right: 0;
            margin-top: 0;
        });
        .mobile({
            padding-right: 0;
            margin-top: 0;
        });
    }

    .mceServices-calendar-wrapper {
        width: 50%;
        margin-right: @base-unit * 4;

        .tablet({
            width: 100%;
        });

        .mobile({
            width: 100%;
        });
    }


    // Use a base-unit for calendar since it is not customizable
    @base-unit: 6px;

    .mceServices-calendar {
        padding: (@base-unit * 2) 0;
        font-size: @base-unit * 3;

        &-month {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: (@base-unit * 5) 0 (@base-unit * 7) 0;

            p {
                margin: 0 (@base-unit * 5);
                min-width: @base-unit * 24;
                text-align: center;
            }

            button {
                background-color: transparent;
                cursor: pointer;
                border: none;
                color: var(--color);
                &:disabled {
                    cursor: not-allowed;
                    opacity: 0.4;
                }
            }
        }

        .tablet({
            &-month {
                margin: (@base-unit * 4) 0;
            }
        });

        .mobile({
            &-month {
                margin: (@base-unit * 4) 0;
            }
        });

        .hide {
            display: none;
        }

        &-days {
            width: 100%;

            td, th {
                text-align: center;
                padding: (@base-unit + 2) 0;
            }

            th {
                font-weight: 500;
            }

            button {
                cursor: pointer;
                background-color: var(--secondaryButtonBackgroundColor);
                border: none;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                box-shadow: 0 0 0 1px var(--secondaryButtonBorderColor);
                color: var(--secondaryButtonColor);
            }

            .translucent_button {
                opacity: 0.7;
            }

            button:disabled {
                cursor: default;
                background-color: transparent;
                border: none;
                box-shadow: none;
                opacity: 0.4;
            }

            .selected-date {
                line-height: 40px;
                border-radius: 20px;
                color: var(--buttonColor);
                background-color: var(--buttonBackgroundColor);
                box-shadow: 0 0 0 1px var(--buttonBorderColor);
            }
        }

        .mobile({
            &-days {
                font-size: calc(var(--baseFontSize) ~"/" 1.5);
                white-space: nowrap;
                td, th {
                    padding: (@base-unit - 1) 0;
                }

                button {
                    height: 30px;
                    width: 30px;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .selected-date {
                line-height: 30px;
                border-radius: 15px;
            }
        });
    }

    .mceServices-time-wrapper {
        width: 50%;
        margin: (@base-unit * 5) 0;
        .tablet({
            width: 100%;
        });

        .mobile({
            width: 100%;
        });
    }

    .mceServices-time {
        &-heading {
            h4, h3 {
                font-family: var(--paragraphFontFamily);
                margin: (@base-unit * 2) 0;

            }

            h3 {
                font-weight: 600;
            }

            p {
                font-size: @base-unit * 2;
                margin: (@base-unit * 6) 0;
            }

            .tablet({
                p {
                    margin: (@base-unit * 4) 0;
                }
            });

            .mobile({
                p {
                    margin: (@base-unit * 4) 0;
                }
            });
        }

        &-slots-grid-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: @base-unit * 2;
            max-height: @base-unit * 38;
            overflow: hidden;
            overflow-y: scroll;
            width: 100%;

            button {
                padding: (@base-unit * 2) @base-unit;
                cursor: pointer;
                background-color: var(--secondaryButtonBackgroundColor);
                border-radius: 0;
                border-width: 0.5px;
                border-color: var(--secondaryButtonBorderColor);
                border-style: solid;
                color: var(--secondaryButtonColor);
                font-size: @base-unit * 3;
                width: 100%;
                .mobile({
                    font-size: @base-unit * 2;
                })
            }

            .selected-time {
                color: var(--buttonColor);
                background-color: var(--buttonBackgroundColor);
                border-color: var(--buttonBorderColor);
            }

            .spots-left {
                text-align: center;
                padding: (@base-unit) 0;
                color: var(--buttonBackgroundColor);
                font-size: calc(var(--fontSize, 0.8) ~"*" var(--baseFontSize));
            }
        }
        .mceServices-time-navigation {
            display: flex;
            .timeslot-nav {
                width: 22px;
                display: none;
                background-color: transparent;
                border: none;
                .mobile({
                    display: block;
                    width: 26px;
                })
            }
        }
        .mobile({
            &-slots-grid-container {
                display: grid;
                grid-template-columns: repeat(120, (@base-unit * 15));
                grid-template-rows: @base-unit * 10;
                overflow-x: scroll;
                overflow-y: hidden;
                // Have scrollbar be below buttons
                padding-bottom: calc(0.75 * 20px);
                margin-bottom: calc(-0.5 * 20px);
            }
        });
    }

    .mceButton {
        button:disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }

    .mceServices-review-appointment {
        margin-top: var(--spacing);
        padding-left: calc(2 ~"*" var(--baseFontSize) ~"+" var(--spacing));
        padding-right: calc(2 ~"*" var(--baseFontSize) ~"+" var(--spacing));

        .mobile({
            padding: 0;
        });

        &-details-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: var(--spacing);

            p {
                flex: 1 1 0px;
            }

            .tablet({
                flex-direction: column;

                p {
                    margin-bottom:  calc(2 ~"*" var(--spacing));
                }
            });

            .mobile({
                flex-direction: column;

                p {
                    margin-bottom:  calc(2 ~"*" var(--spacing));
                }
            });
        }

        &-buttons-container {
            display: flex;
            flex-direction: row;
            margin-top: calc(2 ~"*" var(--spacing));

            .tablet({
                flex-direction: column;
                padding-left: @base-unit;
            });

            .mobile({
                flex-direction: column;
                padding-left: @base-unit;
            });
        }

        .inverse-button {
            margin-right: var(--spacing);
            margin-bottom: var(--spacing);
        }

        &-error {
            background-color: var(--buttonBackgroundColor);
            color: var(--buttonColor);
            padding: var(--spacing);
            margin-bottom: var(--spacing);
        }

        .mceButton {
            justify-self: flex-end;
            white-space: nowrap;
            .mobile({
                width: 100%;
                button {
                    white-space: normal;
                    width: 100%;
                }
            });
        }
    }
});
