@import (reference) "./mixins";

.block(mceText, {
    .useGlobals(
        "headingFontFamily"
        "headingTextColor"
        "linkTextColor"
        "headingFontWeight"
        "baseFontSize"
        "h1FontScale"
        "h2FontScale"
        "h3FontScale"
        "h4FontScale"
        "lineHeight"
    );

    .useVariables("fontSize");

    // Line height is now a global, but doesn't always have a value as it
    // was added later. The fallback here is to catch older sites before it
    // was introduced.
    line-height: var(--lineHeight, 1.5);
    width: 100%;

    a {
        color: var(--linkTextColor);
    }

    h1,
    h2,
    h3,
    h4 {
        font-family: var(--headingFontFamily);
        font-weight: var(--headingFontWeight, 700);
        // Have to use --local here b/c the global does not match
        // TODO: fix this somehow
        color: var(--local-textColor, var(--headingTextColor));
    }

    // Space out children via spacing. We do this via two separate
    // selectors because wrapping elements exist in the editor that
    // are removed when rendering for public consumption.
    > * + *,
    .ProseMirror > * + * {
        margin-top: var(--spacing);
    }

    h1 {
        font-size: calc(var(--h1FontScale) ~"*" var(--baseFontSize));
        line-height: 1.05;
    }

    h2 {
        font-size: calc(var(--h2FontScale) ~"*" var(--baseFontSize));
        line-height: 1.05;
    }

    h3 {
        font-size: calc(var(--h3FontScale) ~"*" var(--baseFontSize));
    }

    h4 {
        font-size: calc(var(--h4FontScale) ~"*" var(--baseFontSize));
    }

    .small-text {
        font-size: calc(var(--baseFontSize) ~"*" 0.8);
    }

    .createModifier(isLogo, {
        a {
            text-decoration: none;
        }
    });

    .createModifier(isNavigation, {
        a {
            text-decoration: none;

            &:hover,
            &[aria-current="page"] {
                border-bottom: 2px solid var(--linkTextColor);
                padding-bottom: 8px;
            }
        }
    });

    .createModifier(noWrap, {
        h1, h2, h3, h4, p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    });
});

.mceText {
    @globals:
        "paragraphFontFamily"
        "paragraphTextColor"
        "baseFontSize";

    .useGlobals(@globals);

    font-size: calc(var(--fontSize, 1) ~"*" var(--baseFontSize));
    font-family: var(--paragraphFontFamily);

    // Have to use --local here b/c the global doesn't match,
    // TODO: fix this somehow
    color: var(--local-textColor, var(--paragraphTextColor));
}

.mceText--bold {
    font-weight: bold !important;
}

.mceText--normal {
    font-weight: normal;
}
