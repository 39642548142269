@import (reference) "./mixins";

.block(mceStackLayout, {
    .useSpacing();
    .useVariables("alignment" "overflow");

    // If the stack layout uses an anchor as the containing element
    // we do not want to decorate the text in internal blocks
    &--isLink {
        text-decoration: none;
    }

    display: flex;
    flex-flow: column;
    overflow: var(--overflow, hidden);
    align-items: var(--alignment);
    margin-top: calc(var(--spacing) ~"/" -2);

    // It would be great to not need these wrappers,
    // but things get tricky with stack layouts
    // nested inside of stack layouts.
    > .mceStackLayout-item {
        margin-top: calc(var(--spacing) ~"/" 2);
    }

    // Contains the items to the width of their parent
    // container. This allows text overflow `Text`
    // blocks to function properly.
    .createModifier(containItems, {
        > .mceStackLayout-item {
            max-width: 100%;
            width: 100%;
        }
    })
});
